"use client";
import React from "react";
import {
  Code,
  Smartphone,
  Layers,
  Cloud,
  Shield,
  PieChart,
} from "lucide-react"; // Import some icons
import TrustBadge from "../Main/TrustBadge";
import Link from "next/link";

const HeroSection = () => {
  // Predefined static positions for each icon to ensure they're nicely distributed
  const staticPositions = [
    { top: "10%", left: "20%" }, // Code icon
    { top: "35%", left: "80%" }, // Smartphone icon
    { top: "60%", left: "25%" }, // Layers icon
    { top: "75%", left: "50%" }, // Cloud icon
    { top: "20%", left: "60%" }, // Shield icon
    { top: "50%", left: "10%" }, // PieChart icon
  ];

  // List of icons to be displayed
  const icons = [Code, Smartphone, Layers, Cloud, Shield, PieChart];

  const words = [
    "innovative software",
    "scalable cloud solutions",
    "cutting-edge mobile apps",
    "AI-powered insights",
    "secure digital platforms",
  ];
  const [currentWord, setCurrentWord] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(
      () => {
        if (!isDeleting && currentWord === words[currentIndex]) {
          setTimeout(() => setIsDeleting(true), 1000);
          return;
        }

        if (isDeleting && currentWord === "") {
          setIsDeleting(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
          return;
        }

        setCurrentWord((prev) =>
          isDeleting
            ? prev.slice(0, -1)
            : words[currentIndex].slice(0, prev.length + 1)
        );
      },
      isDeleting ? 50 : 150
    );

    return () => clearTimeout(timer);
  }, [currentWord, currentIndex, isDeleting]);

  return (
    <section className="relative bg-white sm:py-24 py-8  overflow-hidden">
      {/* Background icons with static positions */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-0">
        {icons.map((Icon, index) => (
          <Icon
            key={index}
            className="absolute sm:w-20 sm:h-20 w-16 h-16 text-blue-100 opacity-50"
            style={staticPositions[index]} // Apply predefined static positions
          />
        ))}
      </div>

      <div className="container mx-auto px-4 flex items-center max-w-7xl relative z-6">
        <div className="w-full text-center">
          <TrustBadge />
          <h1 className="sm:text-7xl text-4xl font-bold mb-4 leading-10 text-center">
            Empowering your business with <br />{" "}
            <span className="text-blue-600">
              {currentWord}
              <span className="animate-blink">|</span>
            </span>
          </h1>
          <p className="text-center mb-6 max-w-3xl mx-auto">
            With over a decade of experience, we specialize in delivering
            tailored solutions that drive efficiency, enhance user experience,
            and foster growth for businesses of all sizes.
          </p>
          <Link
            href="/contact/"
            className="bg-blue-600 text-white px-8 py-3 rounded-full font-medium hover:bg-blue-700 transition duration-300"
          >
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
