"use client";
import React, { useState } from "react";
import Image from "next/image";

const categories = [
  { name: "Frontend", color: "bg-blue-600" },
  { name: "Design", color: "bg-blue-600" },
  { name: "Mobile", color: "bg-blue-600" },
  { name: "eCommerce", color: "bg-blue-600" },
  { name: "CMS", color: "bg-blue-600" },
  { name: "Database", color: "bg-blue-600" },
];

const techStack = {
  Mobile: ["android", "flutter", "kotlin", "react-native", "swift"],
  eCommerce: ["magento", "prestashop", "shopify", "virtuemart", "woocommerce"],
  CMS: ["django", "elementor", "wordpress", "joomla_cms"],
  Database: ["mongodb", "mysql", "postgresql", "redis"],
  Design: ["adobe_illustrator", "adobe_xd", "figma", "photoshop", "sketch"],
  Frontend: [
    "bootstrap",
    "css3",
    "html5",
    "nextjs",
    "react",
    "sass",
    "tailwindcss",
    "typescript",
    "vue",
    "webpack",
  ],
};

const TechStackSection = () => {
  const [activeCategory, setActiveCategory] = useState("Frontend");

  return (
    <section className="py-16">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-bold  sm:text-6xl text-center  mb-6">
          Our Technology Stack
        </h2>
        <p className="text-center text-gray-600 mb-8">
          Leveraging cutting-edge technologies, we deliver software development
          services built on a foundation of both current and emerging tech
          stacks, ensuring innovative and future-ready solutions.
        </p>

        {/* Buttons Stack on Mobile, Row on Larger Screens */}
        <div className="flex flex-col sm:flex-row justify-center my-16 space-y-2 sm:space-y-0 sm:space-x-2">
          {categories.map((category) => (
            <button  key={category.name}   onClick={() => setActiveCategory(category.name)} className={`px-6 py-2 rounded-full font-medium transition-colors    ${activeCategory === category.name  ? category.color + " text-white" : "bg-slate-100  hover:bg-gray-100"}`} > {category.name}</button>
          ))}
        </div>

        <div className="grid grid-cols-[repeat(auto-fit,minmax(120px,1fr))] gap-8 justify-items-center">
  {techStack[activeCategory].map((tech) => (
    <div key={tech} className="flex flex-col items-center">
      <div className="w-16 h-16 relative filter grayscale hover:filter-none transition-all duration-300 cursor-pointer">
        <Image
          src={`/logos/${tech}.svg`}
          alt={tech}
          fill={true}
          style={{ objectFit: "contain" }}
        />
      </div>
      <span className="mt-2 text-sm">
        {tech
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}
      </span>
    </div>
  ))}
</div>


      </div>
    </section>
  );
};

export default TechStackSection;
