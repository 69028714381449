"use client";

import React from "react";
import { ArrowRight, Code, Zap, Users } from "lucide-react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const CTASection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className="py-20 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-4xl mx-auto">
        <motion.h2
          variants={itemVariants}
          className="text-3xl font-bold text-gray-900 sm:text-6xl text-center tracking-tighter"
        >
          Let's Start Building Together!
        </motion.h2>

        <motion.p variants={itemVariants} className="mt-4 text-center">
          Unlock the potential of your business with innovative digital
          solutions tailored for success.
        </motion.p>

        <motion.div
          variants={itemVariants}
          className="mt-10 flex justify-center"
        >
          <a
            href="/contact/"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent  font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out"
          >
            Request a Quote
            <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
          </a>
        </motion.div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
          {[
            {
              title: "Custom Solutions",
              icon: Code,
              description:
                "Tailored software and digital platforms that fit your business needs perfectly.",
            },
            {
              title: "Innovative Technologies",
              icon: Zap,
              description:
                "Utilizing the latest technology stacks to deliver cutting-edge, scalable solutions.",
            },
            {
              title: "Dedicated Experts",
              icon: Users,
              description:
                "Work with a team of skilled professionals committed to driving your success.",
            },
          ].map((item, index) => (
            <motion.div
              key={item.title}
              variants={itemVariants}
              className="border-t border-gray-200 pt-4"
            >
              <div className="flex items-center mb-2 justify-center">
                <item.icon className="h-6 w-6 text-blue-600 mr-2" />
                <h3 className="text-lg font-medium text-gray-900">
                  {item.title}
                </h3>
              </div>
              <p className="text-base text-gray-500 text-center">
                {item.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default CTASection;
