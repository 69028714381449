import React from 'react';
import Image from 'next/image';

const avatars = [
  '/images/1.jpg',
  '/images/2.jpg', 
  '/images/3.jpg',
  '/images/4.jpg',
];

const TrustBadge = () => {
  return (
    <div className="border border-blue-600 border-opacity-10 rounded-full py-1 px-1 flex items-center space-x-3 max-w-[380px] mx-auto sm:mb-8 mb-4 bg-blue-600 bg-opacity-10 uppercase">
      <div className="flex -space-x-2">
        {avatars.map((avatar, index) => (
          <div key={index} className="inline-block h-6 w-6 rounded-full ring-2 ring-white overflow-hidden">
            <Image
              src={avatar}
              alt={`Avatar ${index + 1}`}
              width={24}
              height={24}
              className="rounded-full"
            />
          </div>
        ))}
      </div>
      <div className="flex-grow">
        <p className="sm:text-sm text-xs font-bold text-blue-600">Trusted by Businesses Worldwide</p>
      </div>
    </div>
  );
};

export default TrustBadge;
