import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Footer/CTASection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Header/HeaderHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/Counters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/ServiceShowcase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/TechStack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/TestimonialSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/WorkProcess.tsx");
