"use client";
import React from "react";
import {
  Code,
  Smartphone,
  Layers,
  ShoppingCart,
  Pen,
  FileText,
  Cloud,
  TrendingUp,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
const services = [
  {
    title: "Custom Software",
    href: "/services/custom-software/",
    icon: Code,
    color: "bg-white",
  },
  {
    title: "Mobile Apps",
    href: "/services/mobile-app/",
    icon: Smartphone,
    color: "bg-white",
  },
  {
    title: "Full-Stack Development",
    href: "/services/full-stack/",
    icon: Layers,
    color: "bg-white",
  },
  {
    title: "eCommerce",
    href: "/services/ecommerce/",
    icon: ShoppingCart,
    color: "bg-white",
  },
  {
    title: "UI & UX Design",
    href: "/services/ui-ux-design/",
    icon: Pen,
    color: "bg-white",
  },
  {
    title: "CMS Development",
    href: "/services/cms-development/",
    icon: FileText,
    color: "bg-white",
  },
  {
    title: "Cloud Services",
    href: "/services/cloud/",
    icon: Cloud,
    color: "bg-white",
  },
  {
    title: "Digital Marketing",
    href: "/services/digital-marketing/",
    icon: TrendingUp,
    color: "bg-white",
  },
];

const ServiceIcon = ({ service }) => (
  <a href={service.href} className="cursor-pointer flex flex-col items-center">
    <motion.div
      className={`w-16 h-16 rounded-full flex items-center justify-center  bg-white`}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.95 }}
    >
      <service.icon className="text-blue-600" size={24} />
    </motion.div>
    <p className="mt-2 text-sm font-medium text-center">{service.title}</p>
  </a>
);

const InstagramStyleServicesShowcase = () => {
  return (
    <section className="py-24 bg-blue-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold  sm:text-6xl text-center tracking-tighter mb-6">
            Our Expertise at Your Service
          </h2>
          <p className=" mb-12 text-center">
            Explore our range of innovative solutions designed to elevate your
            business in the digital landscape.
          </p>
        </motion.div>

        {/* Icons Grid */}
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-8 gap-8">
          {services.map((service, index) => (
            <ServiceIcon key={index} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InstagramStyleServicesShowcase;
