"use client"
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Search, Palette, Code, Rocket } from 'lucide-react';

const processSteps = [
  { 
    icon: Search, 
    title: "Discovery & Planning", 
    description: "We gather requirements and develop a strategic plan for your project." 
  },
  { 
    icon: Palette, 
    title: "Design & Architecture", 
    description: "Our team creates intuitive UI/UX designs and robust system architecture." 
  },
  { 
    icon: Code, 
    title: "Development & Testing", 
    description: "We bring your project to life with quality code and rigorous testing." 
  },
  { 
    icon: Rocket, 
    title: "Launch & Support", 
    description: "Your project goes live with ongoing maintenance and support." 
  },
];

const WorkProcessSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100,
      },
    },
  };

  return (
    <section ref={ref} className="py-20 px-4 sm:px-6 lg:px-8 bg-blue-10">
      <motion.div
        className="max-w-6xl mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.h2
          className="text-3xl font-bold sm:text-6xl text-center tracking-tighter mb-6"
          variants={itemVariants}
        >
          Our Work Process
        </motion.h2>
        <motion.p
          className="text-center mb-12"
          variants={itemVariants}
        >
          A Streamlined Approach to Bringing Your Ideas to Life
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {processSteps.map((step, index) => (
            <motion.div
              key={step.title}
              className="bg-white p-6 rounded-xl  relative overflow-hidden"
              variants={itemVariants}
            >
              
              <step.icon strokeWidth={1.2} className="text-blue-600 w-12 h-12 mb-4" />
              <h3 className="text-lg font-bold  mb-2  tracking-tight">{step.title}</h3>
              <p className="">{step.description}</p>
              <div className="absolute bottom-2 right-3 text-5xl font-medium text-blue-100">
                {index + 1}
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="mt-16 text-center"
          variants={itemVariants}
        >
          <a
            href="/process/"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out"
          >
            Learn More About Our Process
          </a>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default WorkProcessSection;