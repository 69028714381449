"use client";
import React, { useState, useEffect, useRef } from "react";

const useCounter = (end, duration = 2000, inView) => {
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    if (!inView) return;

    let startTime;
    let animationFrame;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) {
        animationFrame = requestAnimationFrame(step);
      }
    };
    animationFrame = requestAnimationFrame(step);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration, inView]);

  return count;
};

const Counter = ({ end, label }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  const count = useCounter(end, 2000, inView);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="bg-blue-10 p-6 rounded-lg  text-center">
      <div className="text-4xl font-bold text-blue-600 mb-2">{count}+</div>
      <div className="text-sm text-gray-600">{label}</div>
    </div>
  );
};

const CountersComponent = ({ title, description, counters }) => {
  return (
    <div className="max-w-7xl mx-auto p-8 container sm:py-16 py-8">
      {title && (
        <h2 className="text-3xl font-bold sm:text-6xl text-center mb-6">
          {title}
        </h2>
      )}
      {description && (
        <p className="mb-12 text-center">
          {description}
        </p>
      )}
      <div className="flex justify-center items-center">
        <div className="grid sm:grid-cols-4 gap-4 mb-12 text-center">
          {counters.map((counter, index) => (
            <Counter key={index} end={counter.value} label={counter.label} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountersComponent;